import * as React from 'react'
import { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { groupBy, keys } from 'ramda'
import { ALPHABET_LIST, ENDPOINTS, baseURL } from '../../../constants'
import {
  generateTrackingLink,
  getBrandsLogosURLSrc,
} from '../../../../utils/brandsHelper'
import { useAppState } from '../../../appState'
import CHRContentContainer from '../../contentContainer'
import CHRSpinner from '../../spinner'
import CHRInput from '../../input'
import arrow from '../../../assets/images/brands-arrow.svg'
import searchIcon from '../../../assets/images/app-nav-search.svg'
import { styles } from './styles'
import axios from 'axios'
import { trendingBrandsLogos } from '../../../../utils/trendingBrandsLogos'

interface BrandsAppProps {
  userId: string
  userName: string
}

const mapWordsToChars = groupBy(({ brandName }: { brandName: string }) => {
  if (ALPHABET_LIST.includes(brandName[0].toUpperCase())) {
    return brandName[0].toUpperCase()
  } else {
    return 'others'
  }
})

const BrandsApp = ({ userId, userName }: BrandsAppProps) => {
  const [appState] = useAppState()
  const [
    {
      settings: { commissionSplitPercent },
    },
  ] = useAppState()
  const classes = styles()

  const [searchText, setSearchText] = useState('')
  const [selectedGroup, setSelectedGroup] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [featured, setFeatured] = useState({})
  const [topBrands, setTopBrands] = useState([])
  const [brandsList, setBrandsList] = useState({
    brandsListData: [],
    isBrandsListHere: false,
    isBrandsListLoading: true,
  })

  useEffect(() => {
    setIsLoading(true)

    Promise.allSettled([
      axios.get(`${baseURL}${ENDPOINTS.brandsList}?website=1`),
      axios.get(`${baseURL}${ENDPOINTS.featuredBrands}`),
    ]).then(results => {
      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          switch (index) {
            case 0:
              const { data } = result.value.data
              setBrandsList({
                brandsListData: data,
                isBrandsListHere: true,
                isBrandsListLoading: false,
              })
              const filteredBrands = data.filter(
                (item: { isTrending: any }) => item.isTrending
              )
              const filteredTrendingBrands = getBrandsLogosURLSrc(
                filteredBrands,
                trendingBrandsLogos
              )
              setTopBrands(filteredTrendingBrands)
              break
            case 1:
              setFeatured(
                result.value.data.data.brands.reduce(
                  (
                    acc: { [x: string]: any },
                    v: { brand_id: string | number; brand_name: any }
                  ) => {
                    acc[v.brand_id] = v.brand_name
                    return acc
                  },
                  {}
                )
              )
              break
            default:
              break
          }
          setIsLoading(false)
        } else {
          console.error(`Request ${index} failed with reason:`, result.reason)
          // Handle individual request failures as needed

          if (index === 0) {
            setBrandsList({
              brandsListData: [],
              isBrandsListHere: false,
              isBrandsListLoading: false,
            })
          }
          setIsLoading(false)
        }
      })
    })
  }, [userName])

  useEffect(() => {
    if (brandsList.brandsListData && brandsList.brandsListData.length > 0) {
      let filteredBySearch
      if (searchText) {
        filteredBySearch = brandsList.brandsListData.filter((obj: any) =>
          obj.brandName
            .toLowerCase()
            .trim()
            .includes(searchText.toLowerCase().trim())
        )
      } else {
        filteredBySearch = brandsList.brandsListData
      }
      const result = filteredBySearch
      let AlphabetGroupedList: any = []
      let charsArray: any = []

      AlphabetGroupedList = mapWordsToChars(result)
      charsArray = keys(AlphabetGroupedList)
      charsArray.sort()
      setSelectedKeys(charsArray)
      setSelectedGroup(AlphabetGroupedList)
    } else {
      return
    }
  }, [searchText, brandsList.isBrandsListHere])

  const handleChange = (event: any) => {
    setSearchText(event.target.value)
  }

  if (brandsList.isBrandsListLoading || isLoading) return <CHRSpinner />

  return (
    <>
      <div className={classes.topBrandsContainer}>
        <h3 className={classes.topBrandsTitle}>top brands</h3>
        <div className={classes.topBrandsItems}>
          {topBrands.slice(0, 3).map(brand => (
            <a
              className={classes.topBrandItem}
              href={generateTrackingLink(
                brand,
                {
                  userId: appState.userId,
                  userName: appState.username,
                },
                '',
                '',
                Boolean(appState.userId)
              )}
              target="_blank"
            >
              <img src={brand.src} className={classes.topBrandImage} />
              <p className={classes.topBrandCashBack}>
                {brand.commission} cash back
              </p>
            </a>
          ))}
        </div>
      </div>
      <div className={classes.searchContainer}>
        <img
          src={searchIcon}
          alt="Search Icon"
          className={classes.searchIcon}
        />
        <CHRInput
          type="text"
          value={searchText}
          placeholder="search brands & retailers"
          onChange={e => {
            handleChange(e)
          }}
          customStyles={classes.searchInput}
        />
      </div>
      <CHRContentContainer>
        <>
          {brandsList.brandsListData.length > 0 ? (
            <div className={classes.sortContainer}>
              {selectedKeys.map((char: string) => {
                return (
                  <>
                    <h3 className={classes.brandChar}>{char}</h3>
                    {selectedGroup[char]
                      .sort((a: any, b: any) =>
                        a.brandName.toLowerCase() > b.brandName.toLowerCase()
                          ? 1
                          : -1
                      )
                      .map((item: any) => {
                        let commission = item.commission.includes('Fixed')
                          ? item.commission.replace('Fixed ', 'Fixed $')
                          : item.commission

                        if (commission.includes('$'))
                          commission = `Fixed $${Number(
                            commission.replace('Fixed $', '')
                          ).toFixed(2)}`

                        commission = `(${commission})`
                        if (!item.isCashbackShownInWebsite) commission = ''

                        const isDoubleCashback =
                          Number(item.splitPercent) >
                          commissionSplitPercent / 100
                        return (
                          <div>
                            {featured[item.brandId] ? (
                              <a
                                className={classes.brandName}
                                href={`${
                                  process.env.GATSBY_SITE_URL
                                }/featured-brands/${item.brandName.replace(
                                  /\s+|\/|\\|#/g,
                                  '-'
                                )}/${item.brandId}`}
                                target="_blank"
                              >
                                <div>
                                  <span className={classes.featuredBrand}>
                                    {item.brandName}
                                  </span>
                                  <span className={classes.cashBackText}>
                                    {' '}
                                    {commission}
                                    {isDoubleCashback ? ` 💃🏿` : ''}
                                  </span>
                                </div>
                                <img
                                  src={arrow}
                                  alt="arrow"
                                  className={classes.arrow}
                                />
                              </a>
                            ) : (
                              <a
                                href={generateTrackingLink(
                                  item,
                                  { userId, userName },
                                  '',
                                  '',
                                  Boolean(userId)
                                )}
                                target="_blank"
                                rel="noreferrer"
                                className={classes.brandName}
                              >
                                <div>
                                  {item.brandName}
                                  <span className={classes.cashBackText}>
                                    {' '}
                                    {commission}
                                    {isDoubleCashback ? ` 💃🏿` : ''}
                                  </span>
                                </div>
                                <img
                                  src={arrow}
                                  alt="arrow"
                                  className={classes.arrow}
                                />
                              </a>
                            )}
                          </div>
                        )
                      })}
                  </>
                )
              })}
            </div>
          ) : (
            <div className={classes.brandsListError}>
              <Typography variant="h1" component="h6">
                there is nothing here. try the search box or selecting different
                filters
              </Typography>
            </div>
          )}
          <p className={classes.disclosure}>
            This page contains affiliate links which means Chirpyest may make
            commission from sales generated from the links.
          </p>
        </>
        {!brandsList.isBrandsListLoading && !brandsList.isBrandsListHere && (
          <div className={classes.brandsListError}>
            <Typography variant="h1" component="h6">
              something went wrong. please try refreshing the page or try again
              later.
            </Typography>
          </div>
        )}
      </CHRContentContainer>
    </>
  )
}

export default BrandsApp
