import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { ROUTES } from './../../constants'
import { withTranslation } from 'react-i18next'
import { useAppState } from '../../appState'
import Auth from '@aws-amplify/auth'

const PrivateRoute = ({ component: Component, isFromIos, ...rest }) => {
  const [appState] = useAppState()
  const isLoggedIn = appState.userId > 0
  const urlParams = new URLSearchParams(window.location.search)
  const redirectParam = urlParams.get('redirect')

  useEffect(() => {
    const checkAuth = async () => {
      try {
        let auth = await Auth.currentSession()
        const isAuthenticated = auth.isValid()
        if (!isAuthenticated)
          navigate(
            isFromIos ? `/app/join?redirect=${redirectParam}` : ROUTES.signIn
          )
      } catch (err) {
        navigate(
          isFromIos ? `/app/join?redirect=${redirectParam}` : ROUTES.signIn
        )
      }
    }
    checkAuth()
  }, [])

  return <div>{isLoggedIn && <Component {...rest} />}</div>
}
export default withTranslation()(PrivateRoute)
